/** @mui/material imports */
import Typography from '@mui/material/Typography/Typography';
/** @mui/icons-material imports */
/** React imports */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IS_TEST_ENV } from '../TypeScript/constants';

interface Props {
  user: {
    signInUserSession?: any;
  };
  company: string;
}

const Banner: React.FC<Props> = ({ user, company }): JSX.Element => {
  const { t } = useTranslation();

  const isCompanySwitched: boolean = useMemo((): boolean => {
    return (
      company !== user.signInUserSession?.idToken.payload['custom:company']
    );
  }, [company]);

  const bannerTextString: string = useMemo((): string => {
    const textArr: string[] = [];
    if (IS_TEST_ENV) {
      textArr.push(t('BANNER_TEST_MODE'));
    }
    if (isCompanySwitched) {
      textArr.push(t('BANNER_SWITCH_COMPANY', { company }));
    }
    const textStr: string = textArr.join(' - ');

    return textStr;
  }, [company, IS_TEST_ENV]);

  return (
    <>
      {(IS_TEST_ENV || isCompanySwitched) && (
        <Typography
          color="white"
          fontWeight="600"
          fontSize={{ xs: '0.8rem', md: '1rem' }}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            width: 'fit-content',
            maxWidth: '70%',
            maxHeight: '100%',
            padding: '0.1rem 0.6rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '0 0 4px 4px',
            background: '#ef5350',
          }}
        >
          {bannerTextString}
        </Typography>
      )}
    </>
  );
};

export default Banner;
