/** @mui/material imports */
import Toolbar from '@mui/material/Toolbar/Toolbar';
import Container from '@mui/material/Container/Container';
import Alert from '@mui/material/Alert/Alert';
import AlertTitle from '@mui/material/AlertTitle/AlertTitle';
/** React imports */
import React from 'react';
import { useTranslation } from 'react-i18next';

const HomeCannotUse: React.FC = (props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container
      className="App-header"
      data-testid="content-component"
      maxWidth={false}
    >
      <Toolbar /> {/* Spacer for navbar*/}
      <Alert severity="error" sx={{'margin': '24px 0 0 0'}}>
        <AlertTitle>{t('PAGE_HOMECANNOTUSE_TITLE')}</AlertTitle>
        {t('PAGE_HOMECANNOTUSE_DESCRIPTION')}
      </Alert>
    </Container>
  );
};

export default HomeCannotUse;
