import general from './en-us/general.json';
import tessellation from './en-us/tessellation-app.json';
import projects from './en-us/projects.json';
import changelog from './en-us/changelog.json';
import generalES from './es/general.json';
import tessellationES from './es/tessellation-app.json';
import projectsES from './es/projects.json';
import changelogES from './es/changelog.json';
import generalFR from './fr-ca/general.json';
import tessellationFR from './fr-ca/tessellation-app.json';
import projectsFR from './fr-ca/projects.json';
import changelogFR from './fr-ca/changelog.json';
import generalPT from './pt-br/general.json';
import tessellationPT from './pt-br/tessellation-app.json';
import projectsPT from './pt-br/projects.json';
import changelogPT from './pt-br/changelog.json';

// merge english sources
export const en = {
  translation: Object.assign({}, general, tessellation, projects, changelog),
};

// merge spanish sources
export const es = {
  translation: Object.assign({}, generalES, tessellationES, projectsES, changelogES),
};

// merge french sources
export const fr = {
  translation: Object.assign({}, generalFR, tessellationFR, projectsFR, changelogFR),
};

// merge portuguese sources
export const pt = {
  translation: Object.assign({}, generalPT, tessellationPT, projectsPT, changelogPT),
};
