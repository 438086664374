import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, es, fr, pt } from './language/language-module';

// `supportedLngs` populates language select box in app
i18n.use(initReactI18next).init({
  resources: {
    en,
    es,
    fr,
    pt
  },
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'en',
  supportedLngs: ['en', 'es', 'fr', 'pt'],
});

export default i18n;
