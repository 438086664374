/** @mui/material imports */
import AppBar from '@mui/material/AppBar/AppBar';
import Avatar from '@mui/material/Avatar/Avatar';
import Box from '@mui/material/Box/Box';
import Container from '@mui/material/Container/Container';
import IconButton from '@mui/material/IconButton/IconButton';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography/Typography';
/** @mui/icons-material imports */
import MenuIcon from '@mui/icons-material/Menu';
/** React imports */
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Banner from './Banner';
import { IS_TEST_ENV } from '../TypeScript/constants';

interface Props {
  user: {
    username: string;
    signInUserSession?: any;
    attributes?: any;
    userDataKey?: string;
  };
  signOut: () => void;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDrawerOpen: boolean;
  company: string;
}

const Navbar: React.FC<Props> = ({
  user,
  signOut,
  setIsDrawerOpen,
  isDrawerOpen,
  company,
}): JSX.Element => {
  // MUI sample: https://codesandbox.io/s/boe4oj?file=/demo.tsx
  const [t] = useTranslation();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isGsAdmin, setIsGsAdmin] = useState<boolean>(false);

  useEffect(() => {
    validateIsUserAdmin();
  }, [user]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    // When click OUTSIDE of the user menu
    setAnchorElUser(null);
  };

  const handleSidebar = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const validateIsUserAdmin = (): void => {
    const userCompany: string =
      user.signInUserSession?.idToken.payload['custom:company'] || '';
    const admin: boolean =
      user.signInUserSession?.idToken.payload['custom:admin'] === 'true';
    let result: boolean = false;
    if (userCompany === 'goldspot' && admin) {
      result = true;
    }
    setIsGsAdmin(result);
  };

  return (
    <>
      <AppBar
        position="fixed"
        data-print="false"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        data-testid="navbar-component"
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleSidebar}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Banner user={user} company={company} />
            <Typography
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', lg: 'flex' } }}
            >
              <NavLink to={`/${company}`}>
                <img
                  src="/gs-logo-small.svg"
                  alt="ALS GoldSpot"
                  loading="lazy"
                  height="40"
                  style={{ verticalAlign: 'middle' }}
                />
              </NavLink>
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}
            >
              <img
                src="/als-goldspot-logo.svg"
                alt="ALS GoldSpot"
                loading="lazy"
                height="40"
                style={{ verticalAlign: 'middle' }}
              />
            </Typography>
            <Box
              sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}
            ></Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>{user.username.split('')[0]}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key={user.username} disabled={true}>
                  <Typography textAlign="right">{user.username}</Typography>
                </MenuItem>
                <MenuItem
                  key={'menu-item-changelog'}
                  component={Link}
                  to={`/${company}/changelog`}
                  onClick={handleCloseUserMenu}
                >
                  <Typography textAlign="right">
                    {t('LINK_CHANGELOG')}
                  </Typography>
                </MenuItem>
                {isGsAdmin && (
                  <MenuItem
                    key="goldspot-admin"
                    component="a"
                    target="_blank"
                    href={`https://admin.${
                      IS_TEST_ENV ? 'staging.' : ''
                    }app.goldspot.ca/`}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign={'right'}>
                      {t('NAVIGATION_ITEM_GOLDSPOT_ADMIN')}
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem key="{t('BUTTON_SIGNOUT')}" onClick={signOut}>
                  <Typography textAlign="right">
                    {t('BUTTON_SIGNOUT')}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Navbar;
