/**
 * Get web worker script and initiate the worker;
 * @param path the path to the js file relative to the hostname, eg '/jobs-manager.js'
 */
  export const getWebWorker = (
  path: string
): Promise<{ worker: Worker; workerUrl: string }> => {
  return new Promise((resolve, reject) => {
    const get = new XMLHttpRequest();
    get.open('GET', path, true);
    get.onreadystatechange = function () {
      if (get.readyState == 4 && get.status == 200) {
        const response: string = get.responseText;
        const workerBlob = new Blob([response], { type: 'text/javascript' });
        const workerUrl: string = URL.createObjectURL(workerBlob);
        const worker = new Worker(workerUrl);
        resolve({ worker, workerUrl });
      }
    };
    get.send();
  });
};