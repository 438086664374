export const uploadFileToS3 = (
  file: File,
  pathName: string,
  user: { username: string; signInUserSession?: any; attributes?: any }
): Promise<string> => {
  const token: string = user.signInUserSession.getIdToken().getJwtToken();
  return new Promise((resolve, reject) => {
    const headers: Headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', file.type);
    // can't do x-amz-meta yet as of Sept 2022: https://github.com/boto/boto3/discussions/3342#discussioncomment-3318636
    // headers.append('x-amz-meta-created-by', user.username);
    const requestInfo: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: null,
    };
    const environment: string =
      process.env.REACT_APP_ENVIRONMENT === 'prod'
        ? ''
        : `.${process.env.REACT_APP_ENVIRONMENT}` || '';
    fetch(`https://files${environment}.api.goldspot.ca/${pathName}`, requestInfo)
      .then((response: Response) => {
        const responseBody: Promise<any> = response.json();
        return responseBody;
      })
      .then((responseBody: any) => {
        if (responseBody.result) {
          const headers: Headers = new Headers();
          headers.append('Content-Type', file.type);
          // can't do x-amz-meta yet as of Sept 2022: https://github.com/boto/boto3/discussions/3342#discussioncomment-3318636
          // headers.append('x-amz-meta-created-by', user.username);
          const requestInfo: any = {
            method: 'PUT',
            headers,
            body: file,
          };
          return fetch(responseBody.result, requestInfo);
        } else {
          reject(responseBody.error);
        }
      })
      .then((response?: Response) => {
        const etag: string | null = response?.headers.get('ETag') || null;
        if (etag) {
          const hash: string = JSON.parse(etag);
          resolve(hash);
        } else if (response) {
          const rejection = {
              response: {
                status: 403,
                message: 'timeout'
              }
          };
          reject(rejection);
        } else {
          reject({});
        }
      })
      .catch((reason) => {
        reject(reason.error);
      });
  });
};
