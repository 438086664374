/** @mui/material imports */
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Stack,
} from '@mui/material';
/** @mui/icons-material imports */
import CloseIcon from '@mui/icons-material/Close';
/** React imports */
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
/** Goldspot imports */
import { AlertObject } from '../../TypeScript/interfaces';

export interface AppAlertComponentProps {
  appAlert: AlertObject;
  key?: string;
  handleCloseAlert: () => void;
}
export const AppAlertComponent: React.FC<AppAlertComponentProps> = ({
  appAlert,
  key,
  handleCloseAlert,
}: AppAlertComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] =
    useState<boolean>(false);

  const handleOpenAlertDetails = (): void => {
    setIsDetailsDialogOpen(true);
  };

  const handleCloseAlertDetails = (): void => {
    setIsDetailsDialogOpen(false);
    handleCloseAlert();
  };

  return (
    <Fragment key={key}>
      <Snackbar
        open={true}
        autoHideDuration={appAlert.severity === 'error' ? null : 5000}
      >
        <Alert
          severity={appAlert.severity}
          action={
            <>
              {!!appAlert.details && (
                <Button
                  color={'inherit'}
                  size={'small'}
                  onClick={handleOpenAlertDetails}
                >
                  {t('BUTTON_DETAILS')}
                </Button>
              )}
              <IconButton
                color={'inherit'}
                size={'small'}
                onClick={handleCloseAlert}
              >
                <CloseIcon />
              </IconButton>
            </>
          }
        >
          {appAlert.message}
        </Alert>
      </Snackbar>
      <Dialog
        maxWidth={'sm'}
        open={isDetailsDialogOpen}
        onClose={handleCloseAlertDetails}
        className={`dialog-alert-details ${appAlert.severity}`}
      >
        <DialogTitle>{t('DIALOG_TITLE_ALERT_DETAILS')}</DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          {appAlert.message}
          <Stack>
            <pre
              style={{
                overflow: 'auto',
                // @ts-ignore
                'text-wrap': 'wrap',
                maxHeight: '8rem',
              }}
            >
              {appAlert.details || ''}
            </pre>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button variant="outlined" onClick={handleCloseAlertDetails}>
            {t('BUTTON_CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
