import React, {
  Context,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// COMPONENTS
import Navbar from './Components/Navbar';
import HomeCannotUse from './Components/HomeCannotUse';
import Box from '@mui/material/Box/Box';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// PACKAGES
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import {
  AlertObject,
  DatasetMetadata,
  DatasetRow,
  JobsResponse,
  ProjectObject,
  RecommendationRequest,
  Recommendations,
  SignalMap,
} from './TypeScript/interfaces';
import {
  ColumnHeader,
  JsonDataframe,
  JsonDataframeColumn,
} from './TypeScript/types';
import { errorHandler } from './utils/error/error-handler';
import config from './utils/config';
import { useTranslation } from 'react-i18next';
import { getWebWorker } from './utils/load-web-worker';
import AppScopeLoadingComponent from './utils/components/AppScopeLoading';
import { uploadFileToS3 } from './utils/upload-file-api';
import { StrategyOptions } from './TypeScript/constants';
import { AppAlertComponent } from './utils/components/AppAlert';
const AppRouter = React.lazy(() => {
  return import('./Router/router');
});

interface AppProps {
  user: {
    username: string;
    signInUserSession?: any;
    attributes?: any;
    userDataKey?: string;
  };
  signOut: () => void;
}

export const appContext = createContext({
  appAlerts: [] as AlertObject[] | [],
  setAppAlerts: (value: any): void => {},
  addAppAlert: (value: AlertObject): void => {},
  appLoading: false,
  setAppLoading: (value: boolean): void => {},
  jobs: {} as Record<string, JobsResponse>,
  addJob: (job: JobsResponse): void => {},
  user: {} as {
    username: string;
    signInUserSession?: any;
    attributes?: any;
    userDataKey?: string;
  },
  company: '', // for switch companies
  setCompany: (value: string): void => {},
  useCloud: true,
  toggleUseCloud: (status: boolean): void => {},
  activeProject: {} as ProjectObject,
  setActiveProject: (value: ProjectObject): void => {},
});

export interface WizardContextType {
  wizardInput?: RecommendationRequest;
  setWizardInput: (wizardInput: RecommendationRequest) => void;
  wizardOutput?: Recommendations;
  setWizardOutput: (wizardOutput: Recommendations) => void;
}

export const wizardContext: Context<WizardContextType> = createContext({
  setWizardInput: (wizardInput: RecommendationRequest): void => {},
  setWizardOutput: (wizardOutput: Recommendations): void => {},
});

export interface DataSetContextType {
  uploadedDataset: JsonDataframe;
  metadata: DatasetMetadata;
  setMetadata: (metadata: DatasetMetadata) => void;
  uploadDataset: (dataset: JsonDataframe, metadata: DatasetMetadata) => void;
  setUploadedDataset: (dataset: JsonDataframe) => void;
  deleteUploadedDataset: () => void;
  depthCol: ColumnHeader;
  setDepthCol: (depth_col: ColumnHeader) => void;
  depthMinCol: ColumnHeader;
  setDepthMinCol: (depth_col: ColumnHeader) => void;
  depthMaxCol: ColumnHeader;
  setDepthMaxCol: (depth_col: ColumnHeader) => void;
  maxGap: number;
  setMaxGap: (max_gap: number) => void;
  numberOfClusters: number;
  setNumberOfClusters: (number_of_clusters: number) => void;
  inputSignals: ColumnHeader[];
  setInputSignals: (inputSignals: ColumnHeader[]) => void;
  bhidCol: ColumnHeader;
  setBhidCol: (bhid_col: ColumnHeader) => void;
  clusterMethod?: string;
  setClusterMethod: (cluster_method?: string) => void;
  propagationStrategy: StrategyOptions;
  setPropagationStrategy: (option: StrategyOptions) => void;
  pcaEnabled: boolean;
  setPcaEnabled: (pca: boolean) => void;
  bestKEnabled: boolean;
  setBestKEnabled: (bestK: boolean) => void;
  alterSignalsMap: (
    mappingsToAdd: Record<ColumnHeader, SignalMap>,
    mappingsToRemove?: Record<ColumnHeader, SignalMap>
  ) => void;
  sampleDataset: Record<ColumnHeader, DatasetRow>;
  resampleDataset: () => void;
  createRatioColumn: (
    ratioKey: string,
    visibleKey: string,
    elementColumns: ColumnHeader[]
  ) => Promise<boolean>;
  uploadWaiting: boolean;
  updateUploadWaiting: (
    action: 'start' | 'finish',
    requestReferenceId: string
  ) => void;
}

export const DatasetContext: Context<DataSetContextType> =
  createContext<DataSetContextType>({
    uploadedDataset: {} as JsonDataframe,
    metadata: {} as DatasetMetadata,
    setMetadata: (metadata: DatasetMetadata): void => {},
    uploadDataset: (
      dataset: JsonDataframe,
      metadata: DatasetMetadata
    ): void => {},
    setUploadedDataset: (dataset: JsonDataframe): void => {},
    deleteUploadedDataset: (): void => {},
    inputSignals: [],
    depthCol: '',
    setDepthCol: (depth_col: ColumnHeader): void => {},
    depthMinCol: '',
    setDepthMinCol: (depth_col: ColumnHeader): void => {},
    depthMaxCol: '',
    setDepthMaxCol: (depth_col: ColumnHeader): void => {},
    maxGap: 10,
    setMaxGap: (max_gap: number): void => {},
    numberOfClusters: 3,
    setNumberOfClusters: (number_of_clusters: number): void => {},
    setInputSignals: (inputSignals?: ColumnHeader[]): void => {},
    pcaEnabled: false,
    setPcaEnabled: (pca: boolean): void => {},
    bestKEnabled: false,
    setBestKEnabled: (bestK: boolean): void => {},
    bhidCol: '',
    setBhidCol: (bhid_col?: ColumnHeader): void => {},
    setClusterMethod: (cluster_method?: string): void => {},
    propagationStrategy: StrategyOptions.default,
    setPropagationStrategy: (option: StrategyOptions): void => {},
    alterSignalsMap: (
      mappingsToAdd: Record<ColumnHeader, SignalMap>,
      mappingsToRemove?: Record<ColumnHeader, SignalMap>
    ): void => {},
    sampleDataset: {},
    resampleDataset: () => {},
    createRatioColumn(ratioKey, visibleKey, elementColumns): Promise<boolean> {
      return new Promise(() => {});
    },
    uploadWaiting: false,
    updateUploadWaiting: (
      action: 'start' | 'finish',
      requestReferenceId: string
    ): void => {},
  });

const emptyMetadata: DatasetMetadata = {
  columnHeaders: [],
  columnCount: 0,
  rowCount: 0,
  file: {
    name: '',
    type: 'csv',
    lastModified: 0,
    size: 0,
  },
};

/**
 * Timer to measure inactivity
 * checks for expiration each 5 seconds.
 * session expires after x minutes and warns of logout if y minutes are remaining.
 * x and y are in /src/utils/config.tsx
 */
class IdleTimer {
  timeout: number;
  warned: boolean = false;
  onTimeout;
  interval: NodeJS.Timeout | number = -1;
  warning: () => void = () => {};
  eventHandler: (ev: MouseEvent | KeyboardEvent | Event) => any = (ev) => {};
  timeoutTracker?: NodeJS.Timeout;
  constructor({
    timeout,
    onTimeout,
    onExpired,
    warning,
  }: {
    timeout: number;
    onTimeout: () => void;
    onExpired: () => void;
    warning: () => void;
  }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.warning = warning;
    // check for expired session on instantiation
    const expiredTime = parseInt(
      localStorage.getItem('_inactiveTimer') || '0',
      10
    );
    if (expiredTime > 0 && expiredTime < Date.now()) {
      this.cleanUp();
      onExpired();
      return;
    }
    this.eventHandler = this.updateExpiredTime.bind(this);
    // start tracking 'activity'
    this.tracker();
    // start checking if session expired
    this.startInterval();
  }
  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }
  cleanUp() {
    localStorage.removeItem('_inactiveTimer');
    clearInterval(this.interval as number);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
  // the event listener for the tracker and cleanup
  updateExpiredTime(ev?: MouseEvent | KeyboardEvent | Event): any {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(
        '_inactiveTimer',
        `${Date.now() + this.timeout * 60 * 1000}`
      );
    }, 300);
    return;
  }
  startInterval() {
    const warningPeriod: number = config.session_inactive_warning * 60 * 1000;
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem('_inactiveTimer') || '0',
        10
      );
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.cleanUp();
          this.onTimeout();
        }
      } else if (!this.warned && expiredTime - Date.now() <= warningPeriod) {
        if (this.warning) {
          this.warned = true;
          this.warning();
        }
      }
    }, 5000);
  }
}

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, { meta }) => {
      errorHandler(error as Error);
      // Show error message
      if (meta?.errorMessage) {
        console.error(meta?.errorMessage);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      errorHandler(error as Error);
      // Show error message
      if (mutation.meta?.errorMessage) {
        console.error(mutation.meta?.errorMessage);
      }
    },
  }),
});

const App: React.FC<AppProps> = ({ user, signOut }): JSX.Element => {
  // High level state to control the Side Drawer via sibbling Navbar
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [appAlerts, setAppAlerts] = useState<AlertObject[] | []>([]);
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<string>(
    user.signInUserSession.idToken.payload['custom:company']
  ); //default company
  const [depthCol, setDepthCol] = useState<ColumnHeader>('');
  const [depthMinCol, setDepthMinCol] = useState<ColumnHeader>('');
  const [depthMaxCol, setDepthMaxCol] = useState<ColumnHeader>('');
  const [maxGap, setMaxGap] = useState<number>(10);
  const [numberOfClusters, setNumberOfClusters] = useState<number>(3);
  const [inputSignals, setInputSignals] = useState<ColumnHeader[]>([]);
  const [bhidCol, setBhidCol] = useState<ColumnHeader>('');
  const [clusterMethod, setClusterMethod] = useState<string>();
  const [propagationStrategy, setPropagationStrategy] =
    useState<StrategyOptions>(StrategyOptions.default);
  const [pcaEnabled, setPcaEnabled] = useState<boolean>(false);
  const [bestKEnabled, setBestKEnabled] = useState<boolean>(false);
  const [wizardInput, setWizardInput] = useState<RecommendationRequest>();
  const [wizardOutput, setWizardOutput] = useState<Recommendations>();
  const [jobs, setJobs] = useState<Record<string, JobsResponse>>({});
  const [uploadedDataset, setUploadedDataset] = useState<JsonDataframe>({});
  const [metadata, setMetadata] = useState<DatasetMetadata>(emptyMetadata);
  const [monitorJobWorker, setMonitorJobWorker] = useState<Worker>();
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const [sampleDataset, setSampleDataset] = useState<
    Record<ColumnHeader, DatasetRow>
  >({});
  const [useCloud, setUseCloud] = useState<boolean>(false);
  const [activeProject, setActiveProject] = useState<ProjectObject>({});
  const [uploadWaiting, setUploadWaiting] = useState<boolean>(false);
  const jobsRef = useRef<Record<string, JobsResponse>>(jobs);
  const datasetInitialized = useRef<boolean>(false);
  const { t, i18n } = useTranslation();
  const uploadedDatasetRef = useRef<JsonDataframe>();
  const signalsRef = useRef<Record<ColumnHeader, SignalMap>>(
    metadata.signals || {}
  );
  const pendingUploads = useRef<string[]>([]);

  let cannotUseApp = false; // Need to eveluate this once
  /**
   * Track session expired from inactivity
   */
  useEffect(() => {
    const inactiveTimeoutMinutes = config.session_inactive_timeout;
    const timer = new IdleTimer({
      timeout: inactiveTimeoutMinutes,
      onTimeout: () => {
        // when user is in app and is inactive for an hour
        setIsTimeout(true);
        signOut();
      },
      onExpired: () => {
        // when user opens app and the timer is expired
        setIsTimeout(true);
        signOut();
      },
      warning: () => {
        addAppAlert({
          severity: 'warning',
          message: t('ALERT_INACTIVE_TIMEOUT_WARNING'),
        });
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(uploadedDataset).length > 0) {
      if (
        !datasetInitialized.current ||
        uploadedDataset !== uploadedDatasetRef.current
      ) {
        if (metadata.rowCount > 500) {
          resampleDataset();
        }
      }
      if (datasetInitialized.current && useCloud && metadata.file.name) {
        const pathPrefix: string = getPathPrefix(
          metadata.file.name,
          metadata.file.project
        );
        saveParsedFile(uploadedDataset, pathPrefix);
      }
      datasetInitialized.current = true;
    }
    uploadedDatasetRef.current = uploadedDataset;
  }, [uploadedDataset]);

  /**
   * get/set app language at app init (changes handled in LanguageSwitcher component)
   */
  useEffect(() => {
    const supportsLocalStorage: boolean = window.hasOwnProperty('localStorage');
    const selectedLanguage: string =
      window.localStorage?.getItem(`${user.userDataKey}.selectedLanguage`) ||
      '';
    const defaultLanguage: string = i18n.options.lng || 'en';
    if (supportsLocalStorage && !selectedLanguage) {
      window.localStorage.setItem(
        `${user.userDataKey}.selectedLanguage`,
        defaultLanguage
      );
    } else if (user && supportsLocalStorage && selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [user]);

  /**
   * set column headers from metadata on setting metadata from cloud
   */
  useEffect(() => {
    if (
      !bhidCol &&
      !depthCol &&
      !depthMinCol &&
      !depthMaxCol &&
      metadata.dataset &&
      Object.keys(metadata.dataset).length > 0
    ) {
      if (metadata.dataset.bhidCol && !bhidCol) {
        setBhidCol(metadata.dataset.bhidCol);
      }
      if (metadata.dataset.depthCol && !depthCol) {
        setDepthCol(metadata.dataset.depthCol);
      }
      if (metadata.dataset.depthMinCol && !depthMinCol) {
        setDepthMinCol(metadata.dataset.depthMinCol);
      }
      if (metadata.dataset.depthMaxCol && !depthMaxCol) {
        setDepthMaxCol(metadata.dataset.depthMaxCol);
      }
    }
  }, [metadata]);

  useEffect(() => {
    if (
      depthMinCol &&
      depthMaxCol &&
      uploadedDataset[depthMinCol] &&
      uploadedDataset[depthMaxCol]
    ) {
      addCalculatedDepthToDataset(depthMinCol, depthMaxCol);
    }
  }, [depthMinCol, depthMaxCol]);

  /**
   * If columns are changed in tessellation form, update the metadata
   */
  useEffect(() => {
    if (metadata.dataset) {
      const updatedMappings: Record<string, ColumnHeader> = Object.assign(
        {},
        metadata.dataset
      );
      let changed: boolean = false;
      if (bhidCol && metadata.dataset.bhidCol !== bhidCol) {
        changed = true;
        updatedMappings.bhidCol = bhidCol;
      }
      if (depthCol && metadata.dataset.depthCol !== depthCol) {
        changed = true;
        updatedMappings.depthCol = depthCol;
      }
      if (depthMinCol && metadata.dataset.depthMinCol !== depthMinCol) {
        changed = true;
        updatedMappings.depthMinCol = depthMinCol;
      }
      if (depthMaxCol && metadata.dataset.depthMaxCol !== depthMaxCol) {
        changed = true;
        updatedMappings.depthMaxCol = depthMaxCol;
      }
      if (changed) {
        const updatedMetadata = {
          ...metadata,
          dataset: updatedMappings,
        };
        setMetadata(updatedMetadata);
        if (useCloud) {
          saveMetaData(updatedMetadata);
        }
      }
    }
  }, [bhidCol, depthCol, depthMinCol, depthMaxCol]);

  /**
   * Maintain a React Ref to the jobs context
   */
  useEffect(() => {
    jobsRef.current = jobs;
  }, [jobs]);

  /**
   * Runs when App Component mounts
   * @returns cleanup function to run when App Component unmounts
   */
  useEffect(() => {
    let workerUrl: string = '';
    // get the job manager web worker
    getWebWorker('/jobs-manager.js')
      .then(({ worker, workerUrl }) => {
        // set the message handler for the web worker
        worker.onmessage = handleMonitorJobMessage;
        // set the url in order to be able to revoke it in the cleanup function
        workerUrl = workerUrl;
        // add web worker to state
        setMonitorJobWorker(worker);
      })
      .catch((err) => {
        errorHandler(err);
      });
    return () => {
      // disconnect the web worker
      monitorJobWorker?.terminate();
      // release the virtual url to the web worker script
      URL.revokeObjectURL(workerUrl);
    };
  }, []);

  // maintain a reference to the signals map for the purpose of comparing changes
  useEffect(() => {
    if (metadata.signals && metadata.signals !== signalsRef.current) {
      signalsRef.current = metadata.signals;
    }
  }, [metadata.signals]);

  const getPathPrefix = (
    fileName: string,
    project: string = config.default_project
  ): string => {
    const pathPrefix: string = `/${company}/${project}/tessellation/files/${fileName}`;
    return pathPrefix;
  };

  /**
   * Combines all the actions for a brand-new-from-file dataset so that any actions that
   * need to be done in order can be.
   * @param dataset
   * @param metadata
   */
  const uploadDataset = (
    dataset: JsonDataframe,
    metadata: DatasetMetadata
  ): void => {
    setAppLoading(true);
    aliasColumnHeaders(metadata.columnHeaders)
      .then((mapped: Record<string, any>) => {
        const mappings: Record<string, ColumnHeader> = {};
        if (mapped.bhidCol) {
          setBhidCol(mapped.bhidCol);
          mappings.bhidCol = mapped.bhidCol;
        }
        if (mapped.depthCol) {
          setDepthCol(mapped.depthCol);
          mappings.depthCol = mapped.depthCol;
        }
        if (mapped.depthMinCol) {
          setDepthMinCol(mapped.depthMinCol);
          mappings.depthMinCol = mapped.depthMinCol;
        }
        if (mapped.depthMaxCol) {
          setDepthMaxCol(mapped.depthMaxCol);
          mappings.depthMaxCol = mapped.depthMaxCol;
        }
        if (Object.keys(mappings).length > 0) {
          metadata.dataset = mappings;
        }
        if (mapped.inputSignals) {
          metadata.signals = mapped.inputSignals;
        }
        if (mapped.depthMinCol && mapped.depthMaxCol) {
          const midpoints: JsonDataframeColumn = calculateMidpoints(
            dataset,
            mapped.depthMinCol,
            mapped.depthMaxCol
          );
          dataset['!!_calculated_avg_depth'] = midpoints;
        }
        if (useCloud) {
          const pathPrefix: string = getPathPrefix(
            metadata.file.name,
            metadata.file.project
          );
          saveParsedFile(dataset, pathPrefix)
            .then((hash: string) => {
              metadata.cloud = {
                hash,
                path: pathPrefix,
              };
              setMetadata(metadata);
              setUploadedDataset(dataset);
              saveMetaData(metadata);
              setAppLoading(false);
            })
            .catch((err) => {
              errorHandler(err);
              setUseCloud(false);
              setMetadata(metadata);
              setUploadedDataset(dataset);
              setAppLoading(false);
              addAppAlert({
                severity: 'warning',
                message: t('ALERT_WARNING_ERROR_UPLOADING_CLOUD_DISABLED'),
              });
            });
        } else {
          setMetadata(metadata);
          setUploadedDataset(dataset);
          setAppLoading(false);
        }
      })
      .catch((err) => {
        setUploadedDataset(dataset);
        setMetadata(metadata);
        setAppLoading(false);
        errorHandler(err);
      });
  };

  const deleteUploadedDataset = (): void => {
    setUploadedDataset({});
    datasetInitialized.current = false;
    setMetadata(emptyMetadata);
    setBhidCol('');
    setDepthMaxCol('');
    setDepthMinCol('');
    setDepthCol('');
    setInputSignals([]);
  };

  const calculateMidpoints = (
    dataset: JsonDataframe,
    depthMinCol: ColumnHeader,
    depthMaxCol: ColumnHeader
  ): JsonDataframeColumn => {
    const midpointDepths: JsonDataframeColumn = {};
    const length: number = Object.keys(dataset[depthMinCol]).length;
    for (let i = 0; i < length; i++) {
      const min: number = Number(dataset[depthMinCol][i]);
      const max: number = Number(dataset[depthMaxCol][i]);
      if (!isNaN(min) && !isNaN(max)) {
        const mid: number = (min + max) / 2;
        midpointDepths[`${i}`] = mid;
      } else {
        midpointDepths[`${i}`] = null;
      }
    }
    return midpointDepths;
  };

  const addCalculatedDepthToDataset = (
    depthMinCol: ColumnHeader,
    depthMaxCol: ColumnHeader
  ): void => {
    const midpointDepths: JsonDataframeColumn = calculateMidpoints(
      uploadedDataset,
      depthMinCol,
      depthMaxCol
    );
    const updatedDataset: JsonDataframe = Object.assign({}, uploadedDataset);
    updatedDataset['!!_calculated_avg_depth'] = midpointDepths;
    setUploadedDataset(updatedDataset);
  };

  const handleCloseAlert = (): void => {
    appAlerts.shift(); // Removes the first Alert (closing one Aler at a time)
    setAppAlerts([...appAlerts]);
  };

  const addAppAlert = (alert: AlertObject): void => {
    setAppAlerts([...appAlerts, alert]);
  };

  const signOutAndClearExpiry = () => {
    localStorage.removeItem('_inactiveTimer');
    signOut();
  };

  const aliasColumnHeaders = (
    columnHeaders: ColumnHeader[]
  ): Promise<Record<string, any>> => {
    return new Promise((resolve, reject) => {
      getWebWorker('/predict-column-properties.js').then(
        ({ worker, workerUrl }): void => {
          worker.onmessage = (
            ev: MessageEvent<{
              named: Record<ColumnHeader, string>;
              signals: Record<ColumnHeader, SignalMap>;
            }>
          ) => {
            const mappedColumnHeaders: any = ev.data;
            const mappedColumns: Record<string, any> = {
              bhidCol: '',
              depthCol: '',
              depthMinCol: '',
              depthMaxCol: '',
              logLabelCol: '',
              inputSignals: null,
            };
            if (!!mappedColumnHeaders.bhidCol) {
              mappedColumns.bhidCol = mappedColumnHeaders.bhidCol;
              delete mappedColumnHeaders.bhidCol;
            }
            if (
              !mappedColumnHeaders.depthCol &&
              mappedColumnHeaders.depthMinCol
            ) {
              mappedColumns.depthCol = mappedColumnHeaders.depthMinCol;
            }
            if (
              !mappedColumnHeaders.depthCol &&
              mappedColumnHeaders.depthMaxCol
            ) {
              mappedColumns.depthCol = mappedColumnHeaders.depthMaxCol;
            }
            if (!!mappedColumnHeaders.depthCol) {
              mappedColumns.depthCol = mappedColumnHeaders.depthCol;
              delete mappedColumnHeaders.depthCol;
            }
            if (mappedColumnHeaders.depthMinCol) {
              mappedColumns.depthMinCol = mappedColumnHeaders.depthMinCol;
              delete mappedColumnHeaders.depthMinCol;
            }
            if (mappedColumnHeaders.depthMaxCol) {
              mappedColumns.depthMaxCol = mappedColumnHeaders.depthMaxCol;
              delete mappedColumnHeaders.depthMaxCol;
            }
            if (!!mappedColumnHeaders.logLabelCol) {
              delete mappedColumnHeaders.logLabelCol;
            }
            if (!!mappedColumnHeaders.inputSignals) {
              mappedColumns.inputSignals = mappedColumnHeaders.inputSignals;
            }
            worker.terminate();
            URL.revokeObjectURL(workerUrl);
            resolve(mappedColumns);
          };
          worker.postMessage(columnHeaders);
        }
      );
    });
  };

  /**
   * When web worker posts a message to app, update jobs context if job has
   * a different status from the one in context.
   * Web worker will return the job object when it detects an updated status
   * @param message message from web worker
   */
  const handleMonitorJobMessage = (
    message: MessageEvent<JobsResponse | undefined>
  ): void => {
    if (message.data) {
      const updatedJob: JobsResponse = message.data;
      const existingJobs: Record<string, JobsResponse> = jobsRef.current;
      if (
        existingJobs[updatedJob.id] &&
        existingJobs[updatedJob.id].status !== updatedJob.status
      ) {
        // job status has updated; handle change here;
        const updatedJobs: Record<string, JobsResponse> = Object.assign(
          {},
          existingJobs
        );
        updatedJobs[updatedJob.id] = updatedJob;
        setJobs(updatedJobs);
        if (updatedJob.status && updatedJob.status === 'done') {
          addAppAlert({
            severity: 'info',
            message: `Job done: "${updatedJob.description || updatedJob.id}"`,
          });
        } else if (updatedJob.status && updatedJob.status === 'cancelled') {
          addAppAlert({
            severity: 'info',
            message: `Job cancelled: "${
              updatedJob.description || updatedJob.id
            }"`,
          });
        }
      }
    } else {
      // web worker errored-out
    }
  };

  /**
   * Make a copy of jobs, extend it, then replace the original
   */
  const addJob = (job: JobsResponse): void => {
    const updatedJobs = {
      ...jobs,
      [job.id]: job,
    };
    setJobs(updatedJobs);
    // send job to web worker to monitor
    monitorJob(job);
  };

  /**
   * Pass a job to the job manager (Web Worker) to watch for status updates;
   */
  const monitorJob = (job: JobsResponse): void => {
    const environment: string =
      process.env.REACT_APP_ENVIRONMENT === 'prod'
        ? ''
        : `.${process.env.REACT_APP_ENVIRONMENT}` || '';
    const token: string = user.signInUserSession.getIdToken().getJwtToken();
    const apiHostname: string = `projects${environment}.api.goldspot.ca`;
    monitorJobWorker?.postMessage({ job, token, apiHostname });
  };

  const resampleDataset = (): void => {
    getWebWorker('/sample-dataset.js')
      .then(({ worker, workerUrl }: { worker: Worker; workerUrl: string }) => {
        worker.onmessage = (
          event: MessageEvent<Record<ColumnHeader, DatasetRow>>
        ) => {
          const sample: Record<ColumnHeader, DatasetRow> = event.data;
          setSampleDataset(sample);
          URL.revokeObjectURL(workerUrl);
          worker.terminate();
        };
        worker.postMessage(uploadedDataset);
      })
      .catch((err) => {
        errorHandler(err);
        setSampleDataset(uploadedDataset);
      });
  };

  const alterSignalsMap = (
    mappingToAdd: Record<ColumnHeader, SignalMap>,
    mappingToRemove: Record<ColumnHeader, SignalMap> = {}
  ): void => {
    const columnHeaders: ColumnHeader[] = Object.keys(mappingToAdd);
    const updatedSignalsMap: Record<ColumnHeader, SignalMap> = {};
    const updatedColumnHeaders = metadata.columnHeaders || [];
    if (metadata.signals !== undefined) {
      Object.keys(metadata.signals).forEach((columnHeader: ColumnHeader) => {
        if (
          !mappingToRemove[columnHeader] &&
          metadata.signals !== undefined &&
          metadata.signals[columnHeader]
        ) {
          updatedSignalsMap[columnHeader] = Object.assign(
            {},
            metadata.signals[columnHeader]
          );
        } else if (
          /^ratio_/.test(columnHeader) &&
          mappingToRemove[columnHeader]
        ) {
          // if removing a ratio signal, remove the ratio column header too
          if (updatedColumnHeaders.indexOf(columnHeader) !== -1) {
            const index: number = updatedColumnHeaders.indexOf(columnHeader);
            updatedColumnHeaders.splice(index, 1);
          }
        }
      });
    }
    columnHeaders.forEach((columnHeader: ColumnHeader): void => {
      updatedSignalsMap[columnHeader] = Object.assign(
        {},
        mappingToAdd[columnHeader]
      );
      if (updatedColumnHeaders.indexOf(columnHeader) === -1) {
        updatedColumnHeaders.push(columnHeader);
      }
    });
    const updatedMetadata: DatasetMetadata = {
      ...metadata,
      signals: updatedSignalsMap,
      columnHeaders: updatedColumnHeaders,
    };
    setMetadata(updatedMetadata);
    if (useCloud) {
      saveMetaData(updatedMetadata);
    }
  };

  /**
   * Create the system-generated ratio column
   *
   * Returns column, does not add it to state
   */
  const createRatioColumn = (
    ratioKey: string,
    visibleKey: string,
    elementColumns: ColumnHeader[]
  ): Promise<boolean> => {
    const units: string[] = elementColumns.map(
      (columnHeader: ColumnHeader): string => {
        return metadata.signals?.[columnHeader].signalMeasure as string;
      }
    );
    return new Promise((resolve, reject) => {
      getWebWorker('/create-ratio-column.js').then(({ worker, workerUrl }) => {
        const columns: JsonDataframeColumn[] = [];
        elementColumns.forEach((elementColumn: ColumnHeader) => {
          columns.push(uploadedDataset[elementColumn]);
        });
        worker.onmessage = (event: MessageEvent<JsonDataframeColumn>) => {
          const ratioColumn: JsonDataframeColumn = event.data;
          const updatedDataset: JsonDataframe = { ...uploadedDataset };
          updatedDataset[visibleKey] = ratioColumn;
          worker.terminate();
          URL.revokeObjectURL(workerUrl);
          setUploadedDataset(updatedDataset);
          resolve(true);
        };
        worker.onerror = (event: ErrorEvent) => {
          worker.terminate();
          URL.revokeObjectURL(workerUrl);
          reject();
        };
        worker.postMessage({
          ratioKey,
          columns,
          units,
        });
      });
    });
  };

  const saveMetaData = (metadata: DatasetMetadata): void => {
    const pathPrefix: string = getPathPrefix(
      metadata.file.name,
      metadata.file.project
    );
    const path: string = `${pathPrefix}/metadata.json`;
    const metadataFile = new File([JSON.stringify(metadata)], 'metadata.json', {
      type: 'application/json',
    });
    uploadFileToS3(metadataFile, path, user)
      .then((hash: string) => {})
      .catch((err) => {
        errorHandler(err);
      });
  };

  const updateUploadWaiting = (
    action: 'start' | 'finish',
    requestReferenceId: string
  ): void => {
    if (action === 'start') {
      pendingUploads.current.push(requestReferenceId);
      if (!uploadWaiting) {
        setUploadWaiting(true);
      }
    } else if (action === 'finish') {
      const requestPosition: number =
        pendingUploads.current.indexOf(requestReferenceId);
      pendingUploads.current.splice(requestPosition, 1);
      if (pendingUploads.current.length === 0) {
        setUploadWaiting(false);
      }
    }
  };

  const saveParsedFile = (
    dataset: JsonDataframe,
    pathPrefix: string
  ): Promise<string> => {
    const path: string = `${pathPrefix}/parsed.json`;
    const parsedFile = new File([JSON.stringify(dataset)], 'parsed.json', {
      type: 'application/json',
    });
    const now: Date = new Date();
    const requestReferenceId: string = `req_${now.valueOf}_${
      1000 * Math.random()
    }`;
    return new Promise((resolve, reject) => {
      updateUploadWaiting('start', requestReferenceId);
      uploadFileToS3(parsedFile, path, user)
        .then((hash: string) => {
          updateUploadWaiting('finish', requestReferenceId);
          resolve(hash);
        })
        .catch((err) => {
          handleFailedDatasetUpload();
          addAppAlert({
            severity: 'error',
            message: t('ALERT_ERROR_UPLOAD_TO_CLOUD_FAILED'),
          });
          updateUploadWaiting('finish', requestReferenceId);
          reject(err.error);
        });
    });
  };

  /**
   * When saving dataset to cloud fails, re-sync the local dataset
   */
  const handleFailedDatasetUpload = (): void => {
    const updatedSignalsMap: Record<ColumnHeader, SignalMap> = {};
    const updatedColumnHeaders: ColumnHeader[] = [];
    const updatedInputSignals: ColumnHeader[] = [];
    let changed: boolean = false;
    metadata.columnHeaders.forEach((columnHeader: ColumnHeader) => {
      if (uploadedDatasetRef.current?.hasOwnProperty(columnHeader)) {
        updatedColumnHeaders.push(columnHeader);
        if (metadata.signals && metadata.signals[columnHeader]) {
          updatedSignalsMap[columnHeader] = metadata.signals[columnHeader];
        }
        if (inputSignals.indexOf(columnHeader) !== -1) {
          updatedInputSignals.push(columnHeader);
        }
      } else {
        changed = true;
        delete uploadedDatasetRef.current?.[columnHeader];
      }
    });
    if (changed) {
      setInputSignals(updatedInputSignals);
      setMetadata({
        ...metadata,
        signals: updatedSignalsMap,
        columnHeaders: updatedColumnHeaders,
        columnCount: updatedColumnHeaders.length,
      });
      setUploadedDataset({ ...uploadedDatasetRef.current });
    }
  };

  const toggleUseCloud = (status: boolean): void => {
    setUseCloud(status);
  };

  // User must belong to a company defined it the JWT (['custom:company']). This is required by the API Backends
  if (!user.signInUserSession?.idToken?.payload?.['custom:company']) {
    cannotUseApp = true;
  }

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Box sx={{ display: 'flex' }}>
          <Navbar
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
            user={user}
            company={company}
            signOut={signOutAndClearExpiry}
          />
          {!isTimeout && cannotUseApp ? (
            <HomeCannotUse />
          ) : (
            <appContext.Provider
              value={{
                appAlerts,
                setAppAlerts,
                addAppAlert,
                jobs,
                addJob,
                appLoading,
                setAppLoading,
                user,
                company,
                setCompany,
                useCloud,
                toggleUseCloud,
                activeProject,
                setActiveProject,
              }}
            >
              <DatasetContext.Provider
                value={{
                  uploadedDataset,
                  metadata,
                  setMetadata,
                  uploadDataset,
                  setUploadedDataset,
                  deleteUploadedDataset,
                  depthCol,
                  setDepthCol,
                  depthMinCol,
                  setDepthMinCol,
                  depthMaxCol,
                  setDepthMaxCol,
                  maxGap,
                  setMaxGap,
                  numberOfClusters,
                  setNumberOfClusters,
                  inputSignals,
                  setInputSignals,
                  bhidCol,
                  setBhidCol,
                  clusterMethod,
                  setClusterMethod,
                  propagationStrategy,
                  setPropagationStrategy,
                  pcaEnabled,
                  setPcaEnabled,
                  bestKEnabled,
                  setBestKEnabled,
                  alterSignalsMap,
                  sampleDataset,
                  resampleDataset,
                  createRatioColumn,
                  uploadWaiting,
                  updateUploadWaiting,
                }}
              >
                <wizardContext.Provider
                  value={{
                    wizardInput,
                    setWizardInput,
                    wizardOutput,
                    setWizardOutput,
                  }}
                >
                  <React.Suspense fallback={<AppScopeLoadingComponent />}>
                    <AppRouter
                      user={user}
                      setIsDrawerOpen={setIsDrawerOpen}
                      isDrawerOpen={isDrawerOpen}
                    />
                  </React.Suspense>
                </wizardContext.Provider>
              </DatasetContext.Provider>
            </appContext.Provider>
          )}
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: '2000' }} open={appLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {appAlerts.length > 0 && (
          <AppAlertComponent
            appAlert={appAlerts[0]}
            handleCloseAlert={handleCloseAlert}
          />
        )}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
